@import url('react-big-calendar/lib/css/react-big-calendar.css');


::-webkit-scrollbar {
  display: none;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 1300 !important;
}

.animated-image {
  position: relative;
  animation: bounce 1.5s infinite;
}

.animated-scale {
  position: relative;
  animation: scale 1.5s infinite;
}

.slick-next {
  background-color: #ee2e24 !important;
  border-radius: 50%;
}

.slick-prev {
  background-color: #ee2e24 !important;
  border-radius: 50%;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(20px);
  }
}

@keyframes scale {

  0%,
  100% {
    transform: scale(1.5);
  }

  50% {
    transform: scale(1);
  }
}

/* Shake animation */
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-10px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake-element {
  animation: shake 0.5s ease-in-out;
}

.update-hotelImges {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.update-hotelImge {
  flex: 1 1 150px;
  aspect-ratio: 4/4;
  max-width: 23rem;
  width: 100%;
  border-radius: 10px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  position: relative;
}

.crossIcon {
  transform: rotate(45deg);
  color: white;
  position: absolute;
  cursor: pointer;
  right: 3px;
  top: 3px;
}

.content-betweeen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header {
  z-index: 0 !important;
}

nav {
  z-index: 0 !important;
}

.zindex0 {
  z-index: 0 !important;
}


.ql-editing {
  z-index: 12000;
}

.MuiDataGrid-overlay .css-14349d1 {
  position: relative;
  z-index: 1;
}